import getEnv from "@/utils/env.js";

import store from "@/store/index";

const VUE_APP_API_URL = getEnv("VUE_APP_API_URL");

const axios = require("axios").default;

const http = axios.create({
  withCredentials: true,
});

// Globally catch 401 and ask user for credentials
function errorResponseHandler(error) {
  if (error.response.status === 401) {
    store.dispatch("global/setRequestAuth", true);

    return Promise.reject(error);
  } else {
    return Promise.reject(error);
  }
}

// apply interceptor on response
http.interceptors.response.use((response) => response, errorResponseHandler);

// Get the auth barrer
function getAuth() {
  try {
    if (
      store.state.global.authCredentials !== null &&
      store.state.global.authCredentials.username &&
      store.state.global.authCredentials.password
    ) {
      return {
        username: atob(
          unescape(
            decodeURIComponent(store.state.global.authCredentials.username)
          )
        ),
        password: atob(
          unescape(
            decodeURIComponent(store.state.global.authCredentials.password)
          )
        ),
      };
    } else {
      return {
        username: null,
        password: null,
      };
    }
  } catch (e) {
    return {
      username: null,
      password: null,
    };
  }
}

// Auth
function auth() {
  return http.request({
    method: "POST",
    auth: getAuth(),
    url: `${VUE_APP_API_URL}/authenticate`,
    headers: {
      "content-type": "application/json",
    },
  });
}

// Videos
function getVideos(search = null) {
  const params =
    search !== null ? new URLSearchParams([["search", search]]) : null;

  return http.request({
    method: "GET",
    auth: getAuth(),
    params: params,
    url: `${VUE_APP_API_URL}/videos`,
    headers: {
      "content-type": "application/json",
    },
  });
}

export default {
  // Auth
  auth: auth,

  // Videos
  getVideos: getVideos,
};
